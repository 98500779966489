<template>
  <b-modal
    ref="modal"
    id="ovationSurveyModal"
    hide-footer
    hide-header
    centered
    @hide="close()"
    body-class="p-0"
  >
    <div class="text-center px-2 py-4 px-sm-5 py-sm-5">
      <h1>How are you liking Ovation?</h1>
      <div>Let us know for a chance at a $100 Amazon gift card</div>
      <div class="d-flex flex-row justify-content-center">
        <div
          class="cursor-pointer emoji-icon ml-2 mr-2"
          v-for="emoji in emojiList"
          :key="emoji.score"
          @click="goToSurveyLink(emoji)"
        >
          {{ emoji.emoti }}
        </div>
      </div>
    </div>
  </b-modal>
</template>
<script>
import config from '@/config'
import { surveyRatings } from '@/lib/rating'
import { createNamespacedHelpers } from 'vuex'
const CompanyModule = createNamespacedHelpers('company')
const UserModule = createNamespacedHelpers('user')
import UserService from '../../services/UserService'
import SurveyService from '../../services/SurveyService'
const OVATION_COMPANY_ID = '5d31ed243e1a6d3b9f8c6387'
const OVATION_LOCATION_ID = '5de98d922bb69639e2cdfe17'

export default {
  name: 'ovationSurveyModal',
  methods: {
    ...UserModule.mapActions(['getOnlineUser']),
    async close() {
      /* API call to update survey time*/
      try {
        await UserService.update({
          userId: this.onlineUser._id,
          fields: {
            surveyTimeStamp: new Date(),
          },
        })
        await this.getOnlineUser()
        this.$refs.modal.hide()
      } catch (error) {
        // TODO notification
        console.log(error)
      }
    },
    async goToSurveyLink(emoji) {
      try {
        const customerId = this.onlineUser?.customer_id
        const rating = parseInt(emoji?.score)
        if (!customerId) {
          this.close()
          throw new Error('"customer_id" not found for user')
        }
        const response = await SurveyService.create({
          companyId: OVATION_COMPANY_ID,
          customerId,
          locationId: OVATION_LOCATION_ID,
          rating,
          source: 'website',
        })
        const { surveyId } = response.body
        // Leave Feedback
        let surveyLink = `${config.surveyUrl}/leave-feedback/${surveyId}/${emoji.score}`
        if (emoji.score == 5) {
          // Leave Review
          surveyLink = `${config.surveyUrl}/leave-review/${OVATION_LOCATION_ID}/${customerId}`
        }
        window.open(surveyLink, '_blank')
        this.close()
      } catch (error) {
        // TODO notification
        console.log(error)
      }
    },
  },
  computed: {
    emojiList() {
      return surveyRatings
    },
    ...CompanyModule.mapState(['activeCompany']),
    ...UserModule.mapState(['onlineUser']),
  },
}
</script>
<style>
.emoji-icon {
  font-size: 50px;
}
</style>
